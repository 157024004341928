html {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  max-height: 100vh;
  touch-action: none;
  cursor: default;
  overflow-x: hidden;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  display: flex;
  flex: 1;
  margin: 0;
  font-family: Plain, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

#root {
  display: flex;
  flex: 1;
  width: 100%;
}

@font-face {
  font-family: Plain;
  src: url("/fonts/Plain-Medium.otf") format("opentype");
}
